.background {
    // padding: 30px;
    background-color: rgb(228, 228, 228);
    background-image: url(../../assets/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 600px;
    height: 100%;
}

.header {
  display: flex;
}

.logo {
  background-image: url(../../assets/travelfy-logo.png);
  width: 130px;
  height: 77px;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
}

.content {
  background: #fafafa;
  border-radius: 5px;
}

:--desktop-device .content {
  padding: 10px;
}

.layout {
  min-height: 100vh;
  flex-direction: row;
  display: flex;
}

:--desktop-device .layoutContent {
  margin: 16px;
}

:--mobile-device .layoutContent {
  margin: 10px;
}

.notScrol {
  position: fixed;
  overflow-y: scroll;
}

.header {
  padding: 15px;
  background:#011d38;
  color:#fff
}

.center {
  text-align:center;
}