.nav {
  width: calc(100% - 130px);

  margin-left: auto;
  margin-right: 0;

  text-align: right;

  margin-top: 25px;

  display: flex;

  a {
    line-height: 41px;
    font-weight: bold;
    color: #54acfe;
    padding: 20px;

    &.router-link-exact-active {
      color: rgb(231, 230, 230);
    }
  }
}

.logon {
  margin-left: auto;
  order: 3;
}

.logo {
  height: 85px;
  margin: 28px;
  background-image: url(https://travelfybrasil.com/wp-content/uploads/2023/02/logo-tf.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.layoutSlider {
  background: rgb(103, 181, 255);
  background: linear-gradient(
    90deg,
    rgba(103, 181, 255, 1) 0%,
    rgba(84, 172, 254, 1) 40%,
    rgba(0, 212, 255, 1) 100%
  );
  
}

.mobileSider {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  height:100%;
}